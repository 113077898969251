import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import AdminEditMemberData from "../../data/AdminEditMemberData";

const MemberEditAnggotaMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Edit Data Anggota"} />
      <AdminEditMemberData />
    </div>
  );
};

export default MemberEditAnggotaMenu;
