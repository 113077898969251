import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../custom/CustomTextField";
import { isBlank } from "../../../utils/ValidationRules";
import CustomButton from "../../custom/CustomButton";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const EditPublikasiMenu = () => {
  const [namaPublikasi, setNamaPublikasi] = useState("");
  const [judulPublikasi, setJudulPublikasi] = useState("");
  const { userId } = useParams();
  const { journalId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");

    const fetchJournalData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/journal?journalId=${journalId}&lecturerId=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        const data = response.data.data[0];
        setNamaPublikasi(data.judul);
        setJudulPublikasi(data.nama_jurnal);
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Failed to fetch journal data",
        });
      }
    };

    fetchJournalData();
  }, [journalId, userId]);

  const editPublikasi = (event) => {
    event.preventDefault();
    Swal.fire({
      text: "Merubah data publikasi ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, rubah",
      cancelButtonText: "Tidak, jangan rubah",
    }).then((result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_HOST}/journal`,
            {
              idJurnal: parseInt(journalId, 10),
              namaJurnal: namaPublikasi,
              judul: judulPublikasi,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
            navigate("/tabel-publikasi");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <div>
      <DashboardTitleComponent text={"Edit Publikasi"} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography>*Nama Publikasi</Typography>
          <CustomTextField
            value={namaPublikasi}
            rule={(value) => isBlank(value, "Nama Publikasi")}
            onChange={(event) => setNamaPublikasi(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>*Judul Publikasi</Typography>
          <CustomTextField
            value={judulPublikasi}
            rule={(value) => isBlank(value, "Judul Publikasi")}
            onChange={(event) => setJudulPublikasi(event.target.value)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomButton
            text="Submit"
            baseColor="#003158"
            hoverColor="#00213A"
            onClick={editPublikasi}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditPublikasiMenu;
