import React from "react";
import LayoutNew from "./Layout";
import ResearchMenu from "../components/menu/general/ResearchMenu";

const TabelPenelitian = () => {
  return (
    <LayoutNew>
      <ResearchMenu />
    </LayoutNew>
  );
};
export default TabelPenelitian;
