import React, { useEffect, useState } from "react";
import { Typography, Grid, MenuItem } from "@mui/material";
import CustomTextField from "../custom/CustomTextField";
import CustomSelectField from "../custom/CustomSelectField";
import CustomDatePicker from "../custom/CustomDatePicker";
import CustomNumberField from "../custom/CustomNumberField";
import CustomButton from "../custom/CustomButton";
import { isBlank, isKtpValid } from "../../utils/ValidationRules";
import axios from "axios";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

const UserUpdateData = () => {
  const [ktp, setKtp] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [gelarDepan, setGelarDepan] = useState("");
  const [gelarBelakang, setGelarBelakang] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [negaraAsal, setNegaraAsal] = useState("");
  const [provinsiInstitusi, setProvinsiInstitusi] = useState("");
  const [kotaInstitusi, setKotaInstitusi] = useState("");
  const [namaInstitusi, setNamaInstitusi] = useState("");
  const [jenjangMengajar, setJenjangMengajar] = useState("");
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState("");
  const [jenjangAkademik, setJenjangAkademik] = useState("");
  const [bidangKeahlian1, setBidangKeahlian1] = useState("");
  const [bidangKeahlian2, setBidangKeahlian2] = useState("");
  const [bidangKeahlian3, setBidangKeahlian3] = useState("");
  const [nomorHandphone, setNomorHandphone] = useState("");
  const [programStudi, setProgramStudi] = useState("");
  const [googleScholarId, setGoogleScholarId] = useState("");

  // Store Roles
  const [roleMenuItems, setRoleMenuItems] = useState([]);
  const [negaraAsalItems, setNegaraAsalItems] = useState([]);
  const [provinsiInstitusiItems, setProvinsiInstitusiItems] = useState([]);
  const [kotaInstitusiItems, setKotaInstitusiItems] = useState([]);
  const [namaInstitusiItems, setNamaInstitusiItems] = useState([]);
  const [jenjangMengajarItems, setJenjangMengajarItems] = useState([]);
  const [pendidikanTerakhirItems, setPendidikanTerakhirItems] = useState([]);
  const [jenjangAkademikItems, setJenjangAkademikItems] = useState([]);

  const requestUpdate = async (e) => {
    const jwtToken = localStorage.getItem("token");
    e.preventDefault();
    Swal.fire({
      text: "Lakukan pembaharuan data ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, lakukan",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const provinceLabel = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/provinces?id=${provinsiInstitusi}`
        );
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_HOST}/users/request`,
            {
              namaLengkap: namaLengkap,
              gelarDepan: gelarDepan,
              gelarBelakang: gelarBelakang,
              tempatLahir: tempatLahir,
              tanggalLahir: tanggalLahir,
              namaInstitusi: namaInstitusi,
              kotaInstitusi: kotaInstitusi,
              provinsiInstitusi: provinceLabel.data.data[0].name,
              negaraAsal: negaraAsal,
              programStudi: programStudi,
              jenjangMengajar: jenjangMengajar,
              pendidikanTerakhir: pendidikanTerakhir,
              jenjangAkademik: jenjangAkademik,
              bidangKeahlian1: bidangKeahlian1,
              bidangKeahlian2: bidangKeahlian2,
              bidangKeahlian3: bidangKeahlian3,
              googleScholarId: googleScholarId,
              ktp: ktp,
              nomorHandphone: nomorHandphone,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          roles,
          countries,
          jenjangMengajar,
          pendidikanTerakhir,
          jenjangAkademik,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/roles`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/countries`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/teaching_levels`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/educations`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/academic_levels`),
        ]);

        setRoleMenuItems(roles.data.data);
        setNegaraAsalItems(countries.data.data);
        setJenjangMengajarItems(jenjangMengajar.data.data);
        setPendidikanTerakhirItems(pendidikanTerakhir.data.data);
        setJenjangAkademikItems(jenjangAkademik.data.data);

        if (negaraAsal) {
          const provinces = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/provinces?country_code=${negaraAsal}`
          );
          setProvinsiInstitusiItems(provinces.data.data);
        }

        if (provinsiInstitusi) {
          const regencies = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/regencies?province_id=${provinsiInstitusi}`
          );
          setKotaInstitusiItems(regencies.data.data);
        }

        if (kotaInstitusi) {
          const institutions = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/institutions?regency_name=${kotaInstitusi}`
          );
          setNamaInstitusiItems(institutions.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [negaraAsal, provinsiInstitusi, kotaInstitusi]);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/details/all`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          const data = response.data.data;
          const provinceDetail = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/province?name=${data.provinsi_institusi}`
          );
          const date = dayjs(data.tanggal_lahir);
          setKtp(data.ktp);
          setNamaLengkap(data.nama_lengkap);
          setGelarDepan(data.gelar_depan);
          setGelarBelakang(data.gelar_belakang);
          setTempatLahir(data.tempat_lahir);
          setTanggalLahir(date);
          setNegaraAsal(data.negara_asal);
          setProvinsiInstitusi(provinceDetail.data.data.id);
          setKotaInstitusi(data.kota_institusi);
          setNamaInstitusi(data.institusi_asal);
          setJenjangMengajar(data.jenjang);
          setPendidikanTerakhir(data.pendidikan);
          setJenjangAkademik(data.jenjang_akademik);
          setBidangKeahlian1(data.keahlian_1);
          setBidangKeahlian2(data.keahlian_2);
          setBidangKeahlian3(data.keahlian_3);
          setGoogleScholarId(data.google_scholar_id);
          setNomorHandphone(data.no_hp);
          setProgramStudi(data.prodi);
        } catch (error) {
          alert("Error fetching user details", error);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography>KTP</Typography>
          <CustomNumberField
            value={ktp}
            rule={isKtpValid}
            onChange={(event) => setKtp(event.target.value)}
            maxInputLength={16}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Nama Lengkap</Typography>
          <CustomTextField
            value={namaLengkap}
            rule={(value) => isBlank(value, "Nama Lengkap")}
            onChange={(event) => setNamaLengkap(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Gelar Depan</Typography>
          <CustomTextField
            value={gelarDepan}
            onChange={(event) => setGelarDepan(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Gelar Belakang</Typography>
          <CustomTextField
            value={gelarBelakang}
            onChange={(event) => setGelarBelakang(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Tempat Lahir</Typography>
          <CustomTextField
            value={tempatLahir}
            rule={(value) => isBlank(value, "Tempat Lahir")}
            onChange={(event) => setTempatLahir(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Tanggal Lahir</Typography>
          <CustomDatePicker
            value={tanggalLahir}
            onChange={(event) => setTanggalLahir(event)}
            format={"DD/MM/YYYY"}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>Negara Asal</Typography>
          <CustomSelectField
            value={negaraAsal}
            rule={(value) => isBlank(value, "Negara Asal")}
            onChange={(event) => {
              setNegaraAsal(event.target.value);
              setProvinsiInstitusi("");
              setKotaInstitusi("");
              setNamaInstitusi("");
            }}
            menuItems={negaraAsalItems.map((country, index) => (
              <MenuItem key={index} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Provinsi Institusi</Typography>
          <CustomSelectField
            value={provinsiInstitusi}
            rule={(value) => isBlank(value, "Provinsi Institusi")}
            onChange={(event) => {
              setProvinsiInstitusi(event.target.value);
              setKotaInstitusi("");
              setNamaInstitusi("");
            }}
            disabled={negaraAsal === ""}
            menuItems={provinsiInstitusiItems.map((province, index) => (
              <MenuItem key={index} value={province.id}>
                {province.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Kota Institusi</Typography>
          <CustomSelectField
            value={kotaInstitusi}
            rule={(value) => isBlank(value, "Kota Institusi")}
            onChange={(event) => {
              setKotaInstitusi(event.target.value);
              setNamaInstitusi("");
            }}
            disabled={provinsiInstitusi === ""}
            menuItems={kotaInstitusiItems.map((city, index) => (
              <MenuItem key={index} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Nama Institusi</Typography>
          <CustomSelectField
            value={namaInstitusi}
            rule={(value) => isBlank(value, "Nama Institusi")}
            onChange={(event) => setNamaInstitusi(event.target.value)}
            disabled={kotaInstitusi === ""}
            menuItems={namaInstitusiItems.map((institution, index) => (
              <MenuItem key={index} value={institution.name}>
                {institution.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Program Studi</Typography>
          <CustomTextField
            value={programStudi}
            rule={(value) => isBlank(value, "Program Studi")}
            onChange={(event) => setProgramStudi(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Jenjang Mengajar</Typography>
          <CustomSelectField
            value={jenjangMengajar}
            onChange={(event) => setJenjangMengajar(event.target.value)}
            menuItems={jenjangMengajarItems.map((level, index) => (
              <MenuItem key={index} value={level.name}>
                {level.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Pendidikan Terakhir</Typography>
          <CustomSelectField
            value={pendidikanTerakhir}
            onChange={(event) => setPendidikanTerakhir(event.target.value)}
            menuItems={pendidikanTerakhirItems.map((lastEducation, index) => (
              <MenuItem key={index} value={lastEducation.name}>
                {lastEducation.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Jenjang Akademik</Typography>
          <CustomSelectField
            value={jenjangAkademik}
            onChange={(event) => setJenjangAkademik(event.target.value)}
            menuItems={jenjangAkademikItems.map((academicLevel, index) => (
              <MenuItem key={index} value={academicLevel.name}>
                {academicLevel.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 1</Typography>
          <CustomTextField
            value={bidangKeahlian1}
            onChange={(event) => setBidangKeahlian1(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 2</Typography>
          <CustomTextField
            value={bidangKeahlian2}
            onChange={(event) => setBidangKeahlian2(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 3</Typography>
          <CustomTextField
            value={bidangKeahlian3}
            onChange={(event) => setBidangKeahlian3(event.target.value)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Nomor Handphone</Typography>
          <CustomNumberField
            value={nomorHandphone}
            rule={(value) => isBlank(value, "Nomor Handphone")}
            onChange={(event) => setNomorHandphone(event.target.value)}
            maxInputLength={16}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>Google Scholar Id</Typography>
          <CustomTextField
            value={googleScholarId}
            onChange={(event) => setGoogleScholarId(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomButton
            text="Update"
            baseColor="#003158"
            hoverColor="#00213A"
            onClick={requestUpdate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserUpdateData;
