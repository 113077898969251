import React from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

const DashboardTitleComponent = ({ text }) => {
  return (
    <div>
      <Typography
        variant="h5"
        sx={{
          textTransform: "uppercase",
          marginBottom: "20px",
        }}
      >
        {text}
      </Typography>
      <Divider
        sx={{
          backgroundColor: "gray",
          marginBottom: "20px",
        }}
      />
    </div>
  );
};

export default DashboardTitleComponent;
