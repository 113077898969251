import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import axios from "axios";
import { numberToRupiah } from "../../utils/format";
import { useUser } from "../../pages/UserContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomIconButton from "../custom/CustomIconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import CustomButton from "../custom/CustomButton";

export default function AbmasData() {
  const userDetails = useUser();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_BACKEND_HOST}/abmas/details?isDeleted=0`;
        if (userDetails.role !== "admin") {
          url += `&lecturerId=${userDetails.uuid}`;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        setRows(
          response.data.data.map((abmas, index) => ({
            no: index + 1 + (page - 1) * 10,
            id: abmas.id,
            idDosen: abmas.id_dosen,
            namaAnggota: abmas.lecturer_name,
            kota: abmas.regency_name,
            mulaiPenelitian: abmas.tanggal_mulai,
            topik: abmas.topik,
            sumberBiaya: abmas.abmas_budget[0]?.sumber_biaya || "",
            budget: abmas.abmas_budget[0]
              ? numberToRupiah(abmas.abmas_budget[0].budget)
              : "",
            actual: abmas.abmas_budget[0]
              ? numberToRupiah(abmas.abmas_budget[0].actual)
              : "",
          }))
        );
      } catch (error) {
        console.error("Error fetching journals", error);
      }
    };

    fetchData();
  }, [page, refresh, userDetails.role, userDetails.uuid]);

  const handleAddAbmas = () => {
    navigate(`/insert-pengabdian`);
  };

  const handleEditAbmas = (userId, journalId) => {
    navigate(`/tabel-pengabdian/${userId}/${journalId}`);
  };

  const handleDeleteAbmas = async (abmasId) => {
    Swal.fire({
      text: "Kamu akan menghapus pengabdian ini",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_HOST}/abmas/delete`,
            { id: abmasId },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          Swal.fire({ icon: "success", text: response.data.msg });
          setRefresh(!refresh);
        } catch (error) {
          console.error("Error deleting abmas", error);
          Swal.fire("Failed!", "There was an error deleting abmas.", "error");
        }
      }
    });
  };

  const columns = [
    { field: "no", headerName: "No.", flex: 1 },
    { field: "namaAnggota", headerName: "Nama Anggota", flex: 1 },
    { field: "kota", headerName: "Kota", flex: 1 },
    {
      field: "mulaiPenelitian",
      headerName: "Mulai Penelitian",
      flex: 1,
    },
    { field: "topik", headerName: "Topik", flex: 1 },
    { field: "sumberBiaya", headerName: "Sumber Biaya", flex: 1 },
    { field: "budget", headerName: "Budget", flex: 1 },
    { field: "actual", headerName: "Actual", flex: 1 },
    {
      field: "handleDeleteAbmas",
      headerName: "Action",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CustomIconButton
            isFullWidth={false}
            width={"10px"}
            variant="contained"
            icon={<EditIcon sx={{ color: "WHITE" }} />}
            baseColor="#003158"
            hoverColor="#00213A"
            tooltipText="Edit Pengabdian"
            onClick={() => handleEditAbmas(params.row.idDosen, params.row.id)}
          />
          <CustomIconButton
            baseColor="#A92027"
            hoverColor="#8C1016"
            tooltipText="Hapus Pengabdian"
            icon={<DeleteIcon sx={{ color: "WHITE" }} />}
            onClick={() => handleDeleteAbmas(params.row.id)}
          />
        </div>
      ),
      flex: 1,
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <CustomButton
        onClick={handleAddAbmas}
        text="Tambah Pengabdian"
        isFullWidth={false}
        baseColor="#003158"
        hoverColor="#00213A"
      />
      <br></br>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
