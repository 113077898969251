import React from "react";
import LayoutNew from "./Layout";
import MemberEditAnggotaMenu from "../components/menu/admin/MemberEditAnggotaMenu";

const AdminEditAnggotaIKDKI = () => {
  return (
    <LayoutNew>
      <MemberEditAnggotaMenu />
    </LayoutNew>
  );
};
export default AdminEditAnggotaIKDKI;
