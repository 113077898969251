import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import React, { useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../custom/CustomTextField";
import { isBlank } from "../../../utils/ValidationRules";
import CustomButton from "../../custom/CustomButton";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const InsertPengajaranMenu = () => {
  const [mataKuliah, setMataKuliah] = useState("");
  const navigate = useNavigate();

  const submitPengajaran = (event) => {
    event.preventDefault();
    Swal.fire({
      text: "Masukkan pengajaran baru ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, masukan",
      cancelButtonText: "Tidak, jangan masukkan",
    }).then((result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_HOST}/instruct`,
            {
              mataKuliah: mataKuliah,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
            navigate("/tabel-pengajaran");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <div>
      <DashboardTitleComponent text={"Insert Pengajaran"} />
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography>*Mata Kuliah</Typography>
          <CustomTextField
            value={mataKuliah}
            rule={(value) => isBlank(value, "Mata Kuliah")}
            onChange={(event) => setMataKuliah(event.target.value)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomButton
            text="Submit"
            baseColor="#003158"
            hoverColor="#00213A"
            onClick={submitPengajaran}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InsertPengajaranMenu;
