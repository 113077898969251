import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import ConfirmationUserRequestData from "../../data/ConfirmationUserRequestData";

const EditUserRequestMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Komparasi Data"} />
      <ConfirmationUserRequestData />
    </div>
  );
};

export default EditUserRequestMenu;
