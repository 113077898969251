import React from "react";
import LayoutNew from "./Layout";
import EditPublikasiMenu from "../components/menu/user/EditPublikasiMenu";

const EditPublikasi = () => {
  return (
    <LayoutNew>
      <EditPublikasiMenu />
    </LayoutNew>
  );
};
export default EditPublikasi;
