import React from "react";
import Layout from "./Layout";
import UpdateUserPage from "../components/menu/user/UpdateUserPage";

const UpdateUser = () => {
  return (
    <Layout>
      <UpdateUserPage />
    </Layout>
  );
};

export default UpdateUser;
