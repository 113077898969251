import React from "react";
import { Box } from "@mui/material";

const CustomFooter = ({ text, isFixed }) => {
  return (
    <div>
      <Box
        sx={{
          position: isFixed ? "fixed" : "relative",
          left: 0,
          right: 0,
          bottom: 0,
          marginTop: "15vh",
          backgroundColor: "#A92027",
          color: "white",
          textAlign: "center",
          p: 2,
        }}
      >
        {text}
      </Box>
    </div>
  );
};

export default CustomFooter;
