import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import formatDateTimestamp from "../../utils/date";
import { useNavigate } from "react-router-dom";
import CustomIconButton from "../custom/CustomIconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function UserUpdateRequestData() {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();

  const handleViewChanges = (requestId, userId) => {
    navigate(`/update-confirmation/${requestId}/${userId}`);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_HOST}/users/request?status=pending`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((response) => {
        setRows(
          response.data.data.map((request, index) => ({
            id: index + 1 + (page - 1) * 10,
            namaLengkap: request.user.nama_lengkap,
            universitas: request.user.institusi_asal,
            provinsi: request.user.provinsi_institusi,
            jenjang: request.user.jenjang,
            requestedAt: formatDateTimestamp(request.requested_at),
            userId: request.user_id,
            requestId: request.request_id,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching institutions", error);
      });
  }, [page, refresh]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={[
          { field: "id", headerName: "No.", flex: 1 },
          { field: "namaLengkap", headerName: "Nama Lengkap", flex: 1 },
          { field: "universitas", headerName: "Universitas", flex: 1 },
          { field: "provinsi", headerName: "Provinsi", flex: 1 },
          { field: "jenjang", headerName: "Jenjang", flex: 1 },
          { field: "requestedAt", headerName: "Waktu Request", flex: 1 },
          {
            field: "viewChanges",
            headerName: "View Changes",
            flex: 1,
            renderCell: (cellValues) => (
              // <CustomButton
              // icon={<VisibilityIcon sx={{ color: "WHITE" }} />}
              //   baseColor="#003158"
              //   hoverColor="#00213A"
              //   onClick={() =>
              //     handleViewChanges(
              //       cellValues.row.requestId,
              //       cellValues.row.userId
              //     )
              //   }
              // />
              <CustomIconButton
                baseColor="#003158"
                hoverColor="#00213A"
                tooltipText="Lihat Perubahan"
                icon={<VisibilityIcon sx={{ color: "WHITE" }} />}
                onClick={() =>
                  handleViewChanges(
                    cellValues.row.requestId,
                    cellValues.row.userId
                  )
                }
              />
            ),
          },
        ]}
        pageSize={10}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
