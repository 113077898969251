import React from "react";
import LayoutNew from "./Layout";
import ConfirmationUserRequestMenu from "../components/menu/admin/ConfirmationUserRequestMenu";

const KonfirmasiEditUser = () => {
  return (
    <LayoutNew>
      <ConfirmationUserRequestMenu />
    </LayoutNew>
  );
};
export default KonfirmasiEditUser;
