import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import axios from "axios";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import { useUser } from "../pages/UserContext";

export default function Navbar() {
  const userDetails = useUser();
  const name = userDetails?.nama_lengkap;
  const email = userDetails?.email;
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const { setRole } = useAuth();

  const logout = () => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      axios
        .delete(`${process.env.REACT_APP_BACKEND_HOST}/logout`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          data: { email }, // Pass user email in the request body
        })
        .then(() => {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          setRole(null);
          navigate("/");
        })
        .catch((error) => {
          console.error("Error logging out", error);
        });
    }
  };

  const getFirstName = (fullName) => {
    return fullName ? fullName.split(" ")[0] : "";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#A92027",
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <div>
            {getFirstName(name)}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>Log Out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
