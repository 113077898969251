import React from "react";
import LayoutNew from "./Layout";
import MemberCandidateMenu from "../components/menu/admin/MemberCandidateMenu";

const CalonAnggota = () => {
  return (
    <LayoutNew>
      <MemberCandidateMenu />
    </LayoutNew>
  );
};
export default CalonAnggota;
