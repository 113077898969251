import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import axios from "axios";
import formatDateTimestamp from "../../utils/date";
import CustomIconButton from "../custom/CustomIconButton";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/Info";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export default function MemberCandidateData() {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/users?member=0`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .then((response) => {
        setRows(
          response.data.data.map((user, index) => ({
            id: index + 1 + (page - 1) * 10,
            namaLengkap: user.nama_lengkap,
            universitas: user.institusi_asal,
            provinsi: user.provinsi_institusi,
            email: user.email,
            role: user.role,
            keahlian: `${user.keahlian_1}, ${user.keahlian_2}, ${user.keahlian_3}`,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching users", error);
      });
  }, [page, refresh]);

  const handleUpdateMemberStatus = async (email) => {
    const memberStatus = 1;
    Swal.fire({
      title: "Menerima Calon Anggota",
      text: "Kamu akan menerima calon anggota berikut",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, terima",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        try {
          await axios.put(
            `${process.env.REACT_APP_BACKEND_HOST}/users/member`,
            { email, memberStatus },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          Swal.fire("Accepted!", "Calon anggota telah diterima !", "success");
          setRefresh(!refresh);
        } catch (error) {
          console.error("Error updating member status", error);
          Swal.fire(
            "Failed!",
            "There was an error updating the member status.",
            "error"
          );
        }
      }
    });
  };

  const handleRejectMember = async (email) => {
    const memberStatus = -1;
    Swal.fire({
      title: "Hapus Calon Anggota",
      text: "Kamu akan menghapus calon anggota berikut",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, Hapus",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        try {
          await axios.put(
            `${process.env.REACT_APP_BACKEND_HOST}/users/member`,
            { email, memberStatus },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          Swal.fire(
            "Deleted!",
            "Calon Anggota telah di hapus dari list",
            "success"
          );
          setRefresh(!refresh);
        } catch (error) {
          console.error("Error updating member status", error);
          Swal.fire(
            "Failed!",
            "There was an error updating the member status.",
            "error"
          );
        }
      }
    });
  };

  const handleShowMemberInformation = async (email) => {
    const jwtToken = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/users?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const userData = response.data.data[0];
      const tanggalLahir = formatDateTimestamp(userData.tanggal_lahir);

      Swal.fire({
        title: "User Information",
        html: `
          <div style="text-align: left;">
            <p><strong>KTP :</strong> ${userData.ktp}</p>
            <p><strong>Nama Lengkap :</strong> ${userData.nama_lengkap}</p>
            <p><strong>Gelar Depan :</strong> ${userData.gelar_depan}</p>
            <p><strong>Gelar Belakang :</strong> ${userData.gelar_belakang}</p>
            <p><strong>Tempat Lahir :</strong> ${userData.tempat_lahir}</p>
            <p><strong>Tanggal Lahir :</strong> ${tanggalLahir}</p>
            <p><strong>Negara Asal :</strong> ${userData.negara_asal}</p>
            <p><strong>Provinsi Institusi :</strong> ${userData.provinsi_institusi}</p>
            <p><strong>Kota Institusi :</strong> ${userData.kota_institusi}</p>
            <p><strong>Institusi Asal :</strong> ${userData.institusi_asal}</p>
            <p><strong>Program Studi :</strong> ${userData.prodi}</p>
            <p><strong>Jenjang Mengajar :</strong> ${userData.jenjang}</p>
            <p><strong>Pendidikan Terakhir :</strong> ${userData.jenjang}</p>
            <p><strong>Jenjang Akademik :</strong> ${userData.jenjang}</p>
            <p><strong>Nomor Id :</strong> ${userData.id_users}</p>
            <p><strong>Email :</strong> ${userData.email}</p>
            <p><strong>Keahlian :</strong> ${userData.keahlian_1}, ${userData.keahlian_2}, ${userData.keahlian_3}</p>
            <p><strong>Nomor Handphone :</strong> ${userData.no_hp}</p>
            <p><strong>Role :</strong> ${userData.role}</p>
          </div>
        `,
        icon: "info",
      });
    } catch (error) {
      console.error("Error fetching user information", error);
      Swal.fire(
        "Failed!",
        "There was an error fetching user information.",
        "error"
      );
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={[
          { field: "id", headerName: "No.", flex: 1 },
          { field: "namaLengkap", headerName: "Nama Lengkap", flex: 1 },
          { field: "universitas", headerName: "Universitas", flex: 1 },
          { field: "provinsi", headerName: "Provinsi", flex: 1 },
          { field: "keahlian", headerName: "Keahlian", flex: 1 },
          { field: "role", headerName: "Role", flex: 1 },
          {
            field: "updateMemberStatus",
            headerName: "Action",
            width: 250,
            renderCell: (params) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CustomIconButton
                  variant="contained"
                  baseColor="#28a100"
                  hoverColor="#238f00"
                  disabled={params.row.isMember}
                  tooltipText="Terima Anggota"
                  icon={<CheckIcon sx={{ color: "WHITE" }} />}
                  onClick={() => handleUpdateMemberStatus(params.row.email)}
                />
                <CustomIconButton
                  variant="contained"
                  baseColor="#A92027"
                  hoverColor="#8C1016"
                  disabled={params.row.isMember}
                  tooltipText="Tolak Anggota"
                  icon={<ClearIcon sx={{ color: "WHITE" }} />}
                  onClick={() => handleRejectMember(params.row.email)}
                />
                <CustomIconButton
                  variant="contained"
                  baseColor="#003158"
                  hoverColor="#00213A"
                  disabled={params.row.isMember}
                  tooltipText="Cek Informasi Anggota"
                  icon={<QuestionMarkIcon sx={{ color: "WHITE" }} />}
                  onClick={() => handleShowMemberInformation(params.row.email)}
                />
              </div>
            ),
          },
        ]}
        pageSize={10}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
