import React from "react";
import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import { Grid, Typography, Box } from "@mui/material";
import LecturersByProvinceChart from "../../data/LecturerByProvinceChart";
import LecturerLastAcademicChart from "../../data/LecturerLastAcademicChart";
import LecturerByAcademicLevelChart from "../../data/LecturerByAcademicLevelChart";
import LecturerByTeachingLevelChart from "../../data/LecturerByTeachingLevelChart";
import BarChart from "../../data/BarChart";

const ReportingMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Reporting"} />
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item md={12} xs={12}>
          <Box border={1} borderColor="black" p={2} m={2}>
            <Typography>Grafik Sebaran Dosen Bedasarkan Provinsi</Typography>
            <LecturersByProvinceChart />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item md={6} xs={12}>
          <Box border={1} borderColor="black" p={2} m={2}>
            <Typography>Grafik Jenjang Mengajar Dosen</Typography>
            <LecturerByTeachingLevelChart />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box border={1} borderColor="black" p={2} m={2}>
            <Typography>Grafik Jenjang Akademik Dosen</Typography>
            <LecturerByAcademicLevelChart />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item md={6} xs={12}>
          <Box border={1} borderColor="black" p={2} m={2}>
            <Typography>Grafik Pendidikan Terakhir Dosen</Typography>
            <LecturerLastAcademicChart />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box border={1} borderColor="black" p={2} m={2}>
            <Typography>Grafik Tempat Mengajar Dosen</Typography>
            <BarChart />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportingMenu;
