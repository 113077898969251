import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import InstructData from "../../data/InstructData";

const InstructMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Tabel Pengajaran"} />
      <InstructData />
    </div>
  );
};

export default InstructMenu;
