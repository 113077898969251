import React, { useState } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";

const CustomSelectField = ({
  value,
  onChange,
  placeholder,
  menuItems,
  rule,
  disabled,
  variant,
  ...props
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleValidation = (value) => {
    if (rule) {
      const { isValid, errorMessage } = rule(value);
      setError(!isValid);
      setHelperText(errorMessage);
    }
  };

  const handleChange = (event) => {
    onChange(event);
    handleValidation(event.target.value);
  };

  const handleBlur = () => {
    handleValidation(value);
  };

  return (
    <FormControl fullWidth error={error}>
      <Select
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        variant={disabled ? "filled" : variant}
        sx={{
          borderRadius: 5,
          "&.Mui-disabled:before": {
            borderBottom: "none",
          },
          ...(disabled && {
            "& .MuiFilledInput-input": {
              transform: "translate(5px, -7px)",
            },
          }),
        }}
        {...props}
      >
        <MenuItem disabled value={value}>
          {placeholder}
        </MenuItem>
        {menuItems}
      </Select>
      {error && (
        <FormHelperText>{helperText || "Please select a value"}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomSelectField;
