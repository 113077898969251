import React from "react";
import LayoutNew from "./Layout";
import EditPengajaranMenu from "../components/menu/user/EditPengajaranMenu";

const EditPengajaran = () => {
  return (
    <LayoutNew>
      <EditPengajaranMenu />
    </LayoutNew>
  );
};
export default EditPengajaran;
