import React from "react";
import LayoutNew from "./Layout";
import MemberMenu from "../components/menu/general/MemberMenu";

const AnggotaIKDKI = () => {
  return (
    <LayoutNew>
      <MemberMenu />
    </LayoutNew>
  );
};
export default AnggotaIKDKI;
