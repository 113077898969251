import React from "react";
import LayoutNew from "./Layout";
import AdminProfileMenu from "../components/menu/admin/AdminProfileMenu";

const AdminProfile = () => {
  return (
    <LayoutNew>
      <AdminProfileMenu />
    </LayoutNew>
  );
};
export default AdminProfile;
