import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import MemberCandidateData from "../../data/MemberCandidateData";

const MemberCandidateMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Calon Anggota"} />
      <MemberCandidateData />
    </div>
  );
};

export default MemberCandidateMenu;
