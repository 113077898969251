import dayjs from "dayjs";

export const isBlank = (value, fieldName) => {
  if (value === "") {
    return {
      isValid: false,
      errorMessage: `${fieldName} cannot be empty`,
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const isPasswordValid = (value) => {
  if (value === "") {
    return {
      isValid: false,
      errorMessage: "Password cannot be empty"
    };
  }
  if (value.length < 8) {
    return {
      isValid: false,
      errorMessage: "Password must be at least 8 characters long",
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const isEmailValid = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return {
      isValid: false,
      errorMessage: "Please enter a valid email address",
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const doesPasswordsMatch = (password, confirmPassword) => {
  if (confirmPassword !== password) {
    return {
      isValid: false,
      errorMessage: "Confirm Password Must Same",
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const isPhoneNumberValid = (value) => {
  const phoneNumberRegex = /^\d{10}$/;
  if (!phoneNumberRegex.test(value)) {
    return {
      isValid: false,
      errorMessage: "Please enter a valid phone number",
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const isKtpValid = (value) => {
  const ktpRegex = /^\d{16}$/;
  if (!ktpRegex.test(value)) {
    return {
      isValid: false,
      errorMessage: "KTP number must be exactly 16 digits",
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};

export const isNidnValid = (role, value) => {
  if (role !== "Dosen" || value === "") {
    return {
      isValid: true,
      errorMessage: ""
    };
  }

  const nidnRegex = /^\d{10}$/;
  const isValid = nidnRegex.test(value);
  const errorMessage = isValid ? null : "NIDN number must be exactly 10 digits";

  return {
    isValid,
    errorMessage
  };
};

export const isDateValid = (value) => {
  if (!value) {
    return {
      isValid: false,
      errorMessage: "Date cannot be left blank"
    };
  }
  const today = dayjs();
  if (value.isAfter(today)) {
    return {
      isValid: false,
      errorMessage: "Date cannot be in the future"
    };
  }
  return {
    isValid: true,
    errorMessage: null,
  };
};