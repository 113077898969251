import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import JournalData from "../../data/JournalData";

const JournalMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Tabel Publikasi"} />
      <JournalData />
    </div>
  );
};

export default JournalMenu;
