import React from "react";
import LayoutNew from "./Layout";
import InsertPublikasiMenu from "../components/menu/user/InsertPublikasiMenu";

const IsiPublikasi = () => {
  return (
    <LayoutNew>
      <InsertPublikasiMenu />
    </LayoutNew>
  );
};
export default IsiPublikasi;
