import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  text,
  baseColor,
  hoverColor,
  onClick,
  isFullWidth = true,
  width,
  icon
}) => {
  return (
    <div>
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: baseColor,
          padding: "10px",
          fontSize: "15px",
          "&:hover": {
            backgroundColor: hoverColor,
            color: "#ffffff",
          },
          width: {width}
        }}
        fullWidth={isFullWidth}
        startIcon={icon}
      >
        {text}
      </Button>
    </div>
  );
};

export default CustomButton;
