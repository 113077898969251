import React from "react";
import LayoutNew from "./Layout";
import AbmasMenu from "../components/menu/general/AbmasMenu";

const TabelPengabdian = () => {
  return (
    <LayoutNew>
      <AbmasMenu />
    </LayoutNew>
  );
};
export default TabelPengabdian;
