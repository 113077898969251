import React, { useState, useEffect } from "react";
import { FormControl, TextField } from "@mui/material";
import debounce from "lodash/debounce";

const CustomNumberField = ({
  value: propValue,
  onChange: propOnChange,
  onBlur,
  maxInputLength,
  rule,
  disabled,
  ...props
}) => {
  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const [value, setValue] = useState(propValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleValidation = debounce((value) => {
    if (rule) {
      const { isValid, errorMessage } = rule(value);
      setError(!isValid);
      setHelperText(errorMessage);
    }
  }, 300);

  const handleChange = (event) => {
    if (!maxInputLength || event.target.value.length <= maxInputLength) {
      setValue(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    propOnChange({ target: { value } });
    handleValidation(value);
  };

  return (
    <FormControl fullWidth>
      <TextField
        type="number"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        variant={disabled ? "filled" : "outlined"}
        disabled={disabled}
        helperText={helperText}
        onKeyDown={handleKeyPress}
        InputProps={{
          style: {
            borderRadius: 20,
            fontSize: "15px",
          },
          inputProps: {
            maxLength: maxInputLength,
            pattern: "\\d*",
          },
        }}
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type='number']": {
            MozAppearance: "textfield",
          },
          ...(disabled && {
            "& .MuiFilledInput-root:before": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-root:after": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-root:hover:before": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-input": {
              transform: "translate(5px, -8px)",
            },
          }),
        }}
        fullWidth={true}
        {...props}
      />
    </FormControl>
  );
};

export default CustomNumberField;
