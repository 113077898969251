import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import axios from "axios";
import CustomButton from "../custom/CustomButton";
import { useUser } from "../../pages/UserContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomIconButton from "../custom/CustomIconButton";
import CustomModal from "../custom/CustomModal";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";

export default function JournalData() {
  const userDetails = useUser();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [googleScholarId, setGoogleScholarId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [scholarData, setScholarData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  
  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/details/all`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          setGoogleScholarId(response.data.data.google_scholar_id);
        } catch (error) {
          alert("Error fetching user details", error);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    const fetchData = async () => {
      try {
        let url = `${process.env.REACT_APP_BACKEND_HOST}/journal?isDeleted=0`;
        if (userDetails.role !== "admin") {
          url += `&lecturerId=${userDetails.uuid}`;
        }

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        setRows(
          response.data.data.map((journal, index) => ({
            no: index + 1 + (page - 1) * 10,
            id: journal.id,
            idDosen: journal.id_dosen,
            namaAnggota: journal.lecturer_name,
            judulPublikasi: journal.judul,
            namaPublikasi: journal.nama_jurnal,
          }))
        );
      } catch (error) {
        console.error("Error fetching journals", error);
      }
    };

    fetchData();
  }, [page, refresh, userDetails.role, userDetails.uuid]);

  const handleAddJournal = () => {
    navigate(`/insert-publikasi`);
  };

  const handleEditJournal = (userId, journalId) => {
    navigate(`/tabel-publikasi/${userId}/${journalId}`);
  };

  const handleDeleteJournal = async (journalId) => {
    Swal.fire({
      text: "Kamu akan menghapus publikasi ini",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_HOST}/journal/delete`,
            { id: journalId },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          Swal.fire({
            icon: "success",
            text: response.data.msg,
          });
          setRefresh(!refresh);
        } catch (error) {
          console.error("Error deleting journal", error);
          Swal.fire("Failed!", "There was an error deleting journal.", "error");
        }
      }
    });
  };

  const handleSyncGoogleScholarData = async () => {
    setLoading(true);
    setScholarData(null);
    setError("");
    handleOpenModal();
    const jwtToken = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/journal/scholar?scholarId=${googleScholarId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setScholarData(response.data.data);
      setTotalData(response.data.total);
      setLoading(false);
    } catch (error) {
      console.error("Error syncing Google Scholar data", error);
      setError("There was an error syncing Google Scholar data.");
      setLoading(false);
    }
  };

  const columns = [
    { field: "no", headerName: "No.", flex: 1 },
    { field: "namaAnggota", headerName: "Nama Anggota", flex: 1 },
    { field: "judulPublikasi", headerName: "Judul Publikasi", flex: 1 },
    { field: "namaPublikasi", headerName: "Nama Publikasi", flex: 1 },
    {
      field: "handleActionJurnal",
      headerName: "Action",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CustomIconButton
            isFullWidth={false}
            width={"10px"}
            variant="contained"
            icon={<EditIcon sx={{ color: "WHITE" }} />}
            baseColor="#003158"
            hoverColor="#00213A"
            tooltipText="Edit Publikasi"
            onClick={() => handleEditJournal(params.row.idDosen, params.row.id)}
          />
          <CustomIconButton
            baseColor="#A92027"
            hoverColor="#8C1016"
            tooltipText="Hapus Publikasi"
            icon={<DeleteIcon sx={{ color: "WHITE" }} />}
            onClick={() => handleDeleteJournal(params.row.id)}
          />
        </div>
      ),
      flex: 1,
    },
  ];

  const handleSyncDataButtonClick = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const journalsToSync = scholarData.map((item) => ({
        title: item.title,
        journalName: item.publication,
      }));
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/journal/scholar`,
        { journals: journalsToSync },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      setModalOpen(false);
      Swal.fire({
        icon: "success",
        text: response.data.msg,
      });
      navigate("/tabel-publikasi");
    } catch (error) {
      setModalOpen(false);
      Swal.fire({
        icon: "error",
        text: error.response.data.msg,
      });
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
    <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
      <CustomButton
        onClick={handleSyncGoogleScholarData}
        text="Google Scholar Sync"
        isFullWidth={false}
        baseColor="#003158"
        hoverColor="#00213A"
      />
      <CustomButton
        onClick={handleAddJournal}
        text="Tambah Publikasi"
        isFullWidth={false}
        baseColor="#003158"
        hoverColor="#00213A"
      />
    </div>
      <br></br>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
        slots={{
          toolbar: GridToolbar,
        }}
      />
      <CustomModal
        open={modalOpen}
        onClose={handleCloseModal}
        title="Google Scholar Data"
        loading={loading}
        error={error}
        data={scholarData}
        columns={[
          { field: "title", headerName: "Title" },
          { field: "year", headerName: "Year" },
          { field: "authors", headerName: "Authors" },
          { field: "publication", headerName: "Publication" },
          {
            field: "url",
            headerName: "Link",
            render: (value) => (
              <a href={value} target="_blank" rel="noopener noreferrer">
                View
              </a>
            ),
          },
        ]}
        onSync={handleSyncDataButtonClick}
        showButton={true}
      />
    </div>
  );
}
