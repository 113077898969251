import React, { useState, useEffect } from "react";
import NavbarNew from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Grid, Card, CircularProgress } from "@mui/material";
import { UserProvider } from "./UserContext";

const Layout = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const startTime = Date.now();

    setTimeout(() => {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const minLoadingTime = 500; 
      const remainingTime = minLoadingTime - elapsedTime;
      setTimeout(
        () => {
          setLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
    }, 500);
  }, []);

  
  return (
    <React.Fragment>
      <UserProvider>
        <Grid container>
          <Grid item lg={2} md={3} xs={4}>
            <Sidebar />
          </Grid>
          <Grid item lg={10} md={9} xs={8}>
            <NavbarNew />
            {loading ? (
              <CircularProgress size="8rem" thickness={4} sx={{
                marginTop: "20%",
                marginLeft: "50%",
              }}/>
            ) : (
              <Card
                elevation={2}
                sx={{
                  padding: "2em",
                  margin: "1em",
                }}
              >
                {children}
              </Card>
            )}
          </Grid>
        </Grid>
      </UserProvider>
    </React.Fragment>
  );
};

export default Layout;
