export const formatCamelCaseToCapital = (key) => {
  if (typeof key !== 'string' || !key) {
    return '';
  }
  const splitWords = key.replace(/([A-Z])/g, ' $1');
  const capitalizedWords = splitWords.charAt(0).toUpperCase() + splitWords.slice(1);
  return capitalizedWords;
};

export const formatSnakeCaseToCapital = (key) => {
  const words = key.split('_');
  const capitalizedWords = words.map(word =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');

  return capitalizedWords;
};

export function inverseObject(obj) {
  const retobj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      retobj[obj[key]] = key;
    }
  }
  return retobj;
}

export const numberToRupiah = (amount) => {
  const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  return `Rp${numericAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};