import React from "react";
import LayoutNew from "./Layout";

const PageNotFound = () => {
  return (
    <LayoutNew>
        Page Not Found
    </LayoutNew>
  );
};
export default PageNotFound;
