import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import MemberData from "../../data/MemberData";

const MemberMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"ANGGOTA IKDKI"} />
      <MemberData />
    </div>
  );
};

export default MemberMenu;
