import React from "react";
import LayoutNew from "./Layout";
import InsertPengabdianMenu from "../components/menu/user/InsertPengabdianMenu";

const IsiPengabdian = () => {
  return (
    <LayoutNew>
      <InsertPengabdianMenu />
    </LayoutNew>
  );
};
export default IsiPengabdian;
