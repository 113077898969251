import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomModal = ({
  open,
  onClose,
  title,
  loading,
  error,
  data,
  columns,
  onSync,
  showButton = true,
  pageSize = 5, // Default page size
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = data ? Math.ceil(data.length / pageSize) : 0;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data ? data.length : 0);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "800px", // Limiting maximum width of the modal
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {error}
          </Typography>
        ) : (
          <>
            <TableContainer
              component={Paper}
              sx={{ mt: 2, maxHeight: "400px", overflowY: "auto" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index}>{column.headerName}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data || [])
                    .slice(startIndex, endIndex)
                    .map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {columns.map((column, colIndex) => (
                          <TableCell key={colIndex}>
                            {column.render
                              ? column.render(row[column.field])
                              : row[column.field]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                disabled={currentPage === 1}
                onClick={handlePrevPage}
                sx={{ mr: 2 }}
              >
                Previous
              </Button>
              <Typography>{`Page ${currentPage} of ${totalPages}`}</Typography>
              <Button
                disabled={currentPage === totalPages}
                onClick={handleNextPage}
                sx={{ ml: 2 }}
              >
                Next
              </Button>
            </Box>
            {showButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={onSync}
                sx={{ mt: 2 }}
              >
                Sync Data
              </Button>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;
