import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../custom/CustomTextField";
import { isBlank } from "../../../utils/ValidationRules";
import CustomNumberField from "../../custom/CustomNumberField";
import CustomDatePicker from "../../custom/CustomDatePicker";
import CustomButton from "../../custom/CustomButton";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

const EditPengabdianMenu = () => {
  const [topik, setTopik] = useState("");
  const [sumberBiaya, setSumberBiaya] = useState("");
  const [mulaiPenelitian, setMulaiPenelitian] = useState("");
  const [budget, setBudget] = useState("");
  const [actual, setActual] = useState("");
  const { userId } = useParams();
  const { abmasId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    const fetchAbmasData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/abmas/details?id=${abmasId}&lecturerId=${userId}&is_deleted=0`,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );
        const data = response.data.data[0];
        setMulaiPenelitian(dayjs(data.tanggal_mulai));
        setTopik(data.topik);
        setSumberBiaya(data.abmas_budget[0].sumber_biaya);
        setBudget(data.abmas_budget[0].budget);
        setActual(data.abmas_budget[0].actual);
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Failed to fetch abmas data",
        });
      }
    };

    fetchAbmasData();
  }, [abmasId, userId]);

  const editAbmas = (event) => {
    event.preventDefault();
    Swal.fire({
      text: "Masukkan pengabdian baru ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, masukan",
      cancelButtonText: "Tidak, jangan masukkan",
    }).then((result) => {
      if (result.isConfirmed) {
        const jwtToken = localStorage.getItem("token");
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_HOST}/abmas`,
            {
              id: abmasId,
              mulaiPenelitian: mulaiPenelitian,
              topik: topik,
              sumberBiaya: sumberBiaya,
              budget: budget,
              actual: actual,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
            navigate("/tabel-pengabdian");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <div>
      <DashboardTitleComponent text={"Edit Pengabdian"} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Typography>*Mulai Penelitian</Typography>
          <CustomDatePicker
            value={mulaiPenelitian}
            onChange={(event) => setMulaiPenelitian(event)}
            format={"DD/MM/YYYY"}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>*Topik Penelitian</Typography>
          <CustomTextField
            value={topik}
            rule={(value) => isBlank(value, "Topik Penelitian")}
            onChange={(event) => setTopik(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>*Sumber Biaya</Typography>
          <CustomTextField
            rule={(value) => isBlank(value, "Sumber Biaya")}
            value={sumberBiaya}
            onChange={(event) => setSumberBiaya(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>*Budget</Typography>
          <CustomNumberField
            rule={(value) => isBlank(value, "Budget")}
            value={budget}
            onChange={(event) => setBudget(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>*Actual Budget</Typography>
          <CustomNumberField
            value={actual}
            rule={(value) => isBlank(value, "Actual Budget")}
            onChange={(event) => setActual(event.target.value)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CustomButton
            text="Submit"
            baseColor="#003158"
            hoverColor="#00213A"
            onClick={editAbmas}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditPengabdianMenu;
