import React, { useState } from "react";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";

const CustomAutocompleteField = ({
  value,
  onChange,
  placeholder,
  options,
  rule,
  disabled,
  variant,
  searchBy = "id",
  ...props
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleValidation = (value) => {
    if (rule) {
      const { isValid, errorMessage } = rule(value);
      setError(!isValid);
      setHelperText(errorMessage);
    }
  };

  const handleChange = (event, newValue) => {
    onChange(newValue ? newValue[searchBy] : "");
    handleValidation(newValue ? newValue[searchBy] : "");
  };

  const handleBlur = () => {
    handleValidation(value); // Validasi menggunakan nilai
  };

  return (
    <FormControl fullWidth error={error}>
      <Autocomplete
        value={options.find((option) => option[searchBy] === value) || null}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant={variant}
            error={error}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 5,
                ...(disabled && {
                  backgroundColor: "#E0E0E0",
                }),
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 5,
              },
              ...(disabled && {
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "unset",
                },
              }),
            }}
          />
        )}
        {...props}
      />
      {error && (
        <FormHelperText>{helperText || "Please select a value"}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomAutocompleteField;
