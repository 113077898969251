import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import ResearchData from "../../data/ResearchData";

const ResearchMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Tabel Penelitian"} />
      <ResearchData />
    </div>
  );
};

export default ResearchMenu;
