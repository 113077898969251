import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import UserUpdateRequestData from "../../data/UserUpdateRequestData";

const EditUserRequestMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Konfirmasi Edit User"} />
      <UserUpdateRequestData />
    </div>
  );
};

export default EditUserRequestMenu;
