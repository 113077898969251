import React from "react";
import LayoutNew from "./Layout";
import JournalMenu from "../components/menu/general/JournalMenu";

const TabelPublikasi = () => {
  return (
    <LayoutNew>
      <JournalMenu />
    </LayoutNew>
  );
};
export default TabelPublikasi;
