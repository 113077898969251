import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import AbmasData from "../../data/AbmasData";

const AbmasMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Tabel Pengabdian"} />
      <AbmasData />
    </div>
  );
};

export default AbmasMenu;
