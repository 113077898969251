import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const LecturerLastAcademicChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    try {
      if (jwtToken) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_HOST}/users/role/education-level-count?role=Dosen`,
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                },
              }
            );
            setChartData(response.data.data);

          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }, []);

  useEffect(() => {
    if (chartData) {
      chartInstance.current = new Chart(chartRef.current, {
        type: "doughnut",
        data: {
          labels: ["S1", "S2", "S3"],
          datasets: [
            {
              label: "# jumlah dosen",
              data: [chartData.S1 || 0, chartData.S2 || 0, chartData.S3 || 0],
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(54, 162, 235, 0.5)",
                "rgba(255, 206, 86, 0.5)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef} />;
};

export default LecturerLastAcademicChart;