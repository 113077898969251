import React from "react";
import LayoutNew from "./Layout";
import InstructMenu from "../components/menu/general/InstructMenu";

const TabelPengajaran = () => {
  return (
    <LayoutNew>
      <InstructMenu />
    </LayoutNew>
  );
};
export default TabelPengajaran;
