import { Routes, Route } from 'react-router-dom';
import Login from "./components/Login";
import Waiting from "./components/Waiting";
import RegisterUser from './components/Register';
import CalonAnggota from './pages/CalonAnggota';
import AnggotaIKDKI from './pages/AnggotaIKDKI';
import Institusi from './pages/Institusi';
import Reporting from './pages/Reporting';
import RequestEditUser from './pages/RequestEditUser';
import TabelPengabdian from './pages/TabelPengabdian';
import TabelPenelitian from './pages/TabelPenelitian';
import TabelPublikasi from './pages/TabelPublikasi';
import TabelPengajaran from './pages/TabelPengajaran';
import LogUser from './pages/LogUser';
import UpdateUser from './pages/UpdateUser';
import AdminProfile from './pages/AdminProfile';
import KonfirmasiEditUser from './pages/KonfirmasiEditUser';
import IsiPengabdian from './pages/IsiPengabdian';
import IsiPenelitian from './pages/IsiPenelitian';
import IsiPublikasi from './pages/IsiPublikasi';
import IsiPengajaran from './pages/IsiPengajaran';
import Unauthorized from './components/Unauthorized';
import { useAuth } from './components/AuthProvider';
import PageNotFound from './pages/PageNotFound';
import ResetPassword from './components/ResetPassword';
import ChangeUserPassword from './components/ChangeUserPassword';
import AdminEditAnggotaIKDKI from './pages/AdminEditAnggotaIKDKI';
import EditPublikasi from './pages/EditPublikasi';
import EditPenelitian from './pages/EditPenelitian';
import EditPengabdian from './pages/EditPengabdian';
import EditPengajaran from './pages/EditPengajaran';
import Acara from './pages/Acara';
import LandingPageRakernas from './components/LandingPageRakernas';
import RakernasWaitingRegister from './components/RakernasWaitingRegister';

function App() {
    const { isAuthenticated, role } = useAuth();
    const validateRole = (allowedRoles, component) => {
        if (isAuthenticated && allowedRoles.includes(role)) {
            return component;
        } else {
            return <PageNotFound />;
        }
    };

    const routes = [
        // harusnya nanti di validasi 
        { path: "*", element: <PageNotFound /> },
        { path: "/", element: <Login /> },
        { path: "/reset-password", element: <ResetPassword /> },
        { path: "/waiting", element: <Waiting /> },
        { path: "/register", element: <RegisterUser /> },
        { path: "/dashboard", element: <AdminProfile /> },
        { path: "/calon-anggota", element: <CalonAnggota /> },
        { path: "/anggota", element: validateRole(["admin"], <AnggotaIKDKI />) },
        { path: "/anggota/:userId", element: <AdminEditAnggotaIKDKI /> },
        { path: "/institusi", element: <Institusi /> },
        { path: "/reporting", element: <Reporting /> },
        { path: "/edit-user", element: <RequestEditUser /> },
        { path: "/tabel-pengabdian", element: <TabelPengabdian /> },
        { path: "/tabel-pengabdian/:userId/:abmasId", element: <EditPengabdian /> },
        { path: "/tabel-penelitian", element: <TabelPenelitian /> },
        { path: "/tabel-penelitian/:userId/:researchId", element: <EditPenelitian /> },
        { path: "/tabel-publikasi", element: <TabelPublikasi /> },
        { path: "/tabel-publikasi/:userId/:journalId", element: <EditPublikasi /> },
        { path: "/tabel-pengajaran", element: <TabelPengajaran /> },
        { path: "/tabel-pengajaran/:userId/:instructId", element: <EditPengajaran /> },
        { path: "/loguser", element: <LogUser /> },
        { path: "/update", element: <UpdateUser /> },
        { path: "/update-confirmation/:requestId/:userId", element: <KonfirmasiEditUser /> },
        { path: "/insert-pengabdian", element: <IsiPengabdian /> },
        { path: "/insert-penelitian", element: <IsiPenelitian /> },
        { path: "/insert-publikasi", element: <IsiPublikasi /> },
        { path: "/insert-pengajaran", element: <IsiPengajaran /> },
        { path: "/change-user-password/:token", element: <ChangeUserPassword /> },
        { path: "/unauthorized", element: <Unauthorized /> },
        // { path: "/acara", element: <Acara /> },
        // Rakernas
        { path: "/tabel-pendaftaran-rakernas", element: <LandingPageRakernas /> },
        { path: "/pendaftaran-rakernas", element: <LandingPageRakernas /> },
        { path: "/success-rakernas-registration", element: <RakernasWaitingRegister /> },
    ];

    return (
        <div>
            <Routes>
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element} />
                ))}

            </Routes>
        </div>
    );
}

export default App;
