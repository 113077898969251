import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";

export default function InstitutionalData() {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/institutions`, {})
      .then((response) => {
        setRows(
          response.data.data.map((institution, index) => ({
            id: index + 1 + (page - 1) * 10,
            code: institution.code,
            name: institution.name,
            city: institution.city,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching institutions", error);
      });
  }, [page]);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={[
          { field: "id", headerName: "No.", flex: 1 },
          { field: "code", headerName: "Kode Universitas", flex: 1 },
          { field: "name", headerName: "Nama Universitas", flex: 1 },
          { field: "city", headerName: "Kota Universitas", flex: 1 },
        ]}
        pageSize={10}
        pagination
        onPageChange={(newPage) => setPage(newPage)}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
