import React from "react";
import LayoutNew from "./Layout";
import EditPenelitianMenu from "../components/menu/user/EditPenelitianMenu";

const EditPenelitian = () => {
  return (
    <LayoutNew>
      <EditPenelitianMenu />
    </LayoutNew>
  );
};
export default EditPenelitian;
