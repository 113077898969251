import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import InstitutionalData from "../../data/InstitutionalData";

const InstitutionsMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Tabel Institusi"} />
      <InstitutionalData />
    </div>
  );
};

export default InstitutionsMenu;
