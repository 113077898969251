import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import CustomTextField from "./custom/CustomTextField";
import CustomSelectField from "./custom/CustomSelectField";
import CustomDatePicker from "./custom/CustomDatePicker";
import CustomNumberField from "./custom/CustomNumberField";
import CustomButton from "./custom/CustomButton";
import CustomFooter from "./custom/CustomFooter";
import Container from "@mui/material/Container";
import { MenuItem } from "@mui/material";
import {
  isBlank,
  isPasswordValid,
  doesPasswordsMatch,
  isEmailValid,
  isKtpValid,
  isNidnValid,
} from "../utils/ValidationRules";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import CustomImageUpload from "./custom/CustomImageUpload";
import CustomAutocompleteField from "./custom/CustomAutoCompleteField";

const Register = () => {
  const [role, setRole] = useState("");
  const [negaraAsal, setNegaraAsal] = useState("");
  const [provinsiInstitusi, setProvinsiInstitusi] = useState("");
  const [kotaInstitusi, setKotaInstitusi] = useState("");
  const [namaInstitusi, setNamaInstitusi] = useState("");
  const [jenjangMengajar, setJenjangMengajar] = useState("");
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState("");
  const [jenjangAkademik, setJenjangAkademik] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [email, setEmail] = useState("");
  const [ktp, setKtp] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [gelarDepan, setGelarDepan] = useState("");
  const [gelarBelakang, setGelarBelakang] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [programStudi, setProgramStudi] = useState("");
  const [bidangKeahlian1, setBidangKeahlian1] = useState("");
  const [bidangKeahlian2, setBidangKeahlian2] = useState("");
  const [bidangKeahlian3, setBidangKeahlian3] = useState("");
  const [nidn, setNidn] = useState("");
  const [nomorHandphone, setNomorHandphone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // handle image upload
  const handleImageUpload = (file) => {
    console.log("Uploaded file:", file);
  };

  // Store Roles
  const [roleMenuItems, setRoleMenuItems] = useState([]);
  const [negaraAsalItems, setNegaraAsalItems] = useState([]);
  const [provinsiInstitusiItems, setProvinsiInstitusiItems] = useState([]);
  const [kotaInstitusiItems, setKotaInstitusiItems] = useState([]);
  const [namaInstitusiItems, setNamaInstitusiItems] = useState([]);
  const [jenjangMengajarItems, setJenjangMengajarItems] = useState([]);
  const [pendidikanTerakhirItems, setPendidikanTerakhirItems] = useState([]);
  const [jenjangAkademikItems, setJenjangAkademikItems] = useState([]);
  const navigate = useNavigate();

  const linkStyle = {
    fontWeight: "bold",
    textDecoration: "none",
    color: "#003158",
    "&:hover": {
      color: "#00213A",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          roles,
          countries,
          jenjangMengajar,
          pendidikanTerakhir,
          jenjangAkademik,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/roles`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/countries`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/teaching_levels`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/educations`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/academic_levels`),
        ]);

        setRoleMenuItems(roles.data.data);
        setNegaraAsalItems(countries.data.data);
        setJenjangMengajarItems(jenjangMengajar.data.data);
        setPendidikanTerakhirItems(pendidikanTerakhir.data.data);
        setJenjangAkademikItems(jenjangAkademik.data.data);

        if (negaraAsal) {
          const provinces = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/provinces?country_code=${negaraAsal}`
          );
          setProvinsiInstitusiItems(provinces.data.data);
        }

        if (provinsiInstitusi) {
          const regencies = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/regencies?province_id=${provinsiInstitusi}`
          );
          setKotaInstitusiItems(regencies.data.data);
        }

        if (kotaInstitusi) {
          const institutions = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/institutions?regency_name=${kotaInstitusi}`
          );
          setNamaInstitusiItems(institutions.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [negaraAsal, provinsiInstitusi, kotaInstitusi]);
  const registerUser = async (e) => {
    e.preventDefault();
    try {
      const provinceLabel = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/provinces?id=${provinsiInstitusi}`
      );
      await axios.post(`${process.env.REACT_APP_BACKEND_HOST}/users`, {
        namaLengkap: namaLengkap,
        gelarDepan: gelarDepan,
        gelarBelakang: gelarBelakang,
        tempatLahir: tempatLahir,
        tanggalLahir: tanggalLahir,
        namaInstitusi: namaInstitusi,
        kotaInstitusi: kotaInstitusi,
        provinsiInstitusi: provinceLabel.data.data[0].name,
        negaraAsal: negaraAsal,
        programStudi: programStudi,
        jenjangMengajar: jenjangAkademik,
        pendidikanTerakhir: pendidikanTerakhir,
        jenjangAkademik: jenjangAkademik,
        bidangKeahlian1: bidangKeahlian1,
        bidangKeahlian2: bidangKeahlian2,
        bidangKeahlian3: bidangKeahlian3,
        nidn: nidn,
        ktp: ktp,
        nomorHandphone: nomorHandphone,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        role: role,
      });
      Swal.fire({
        icon: "success",
        text: "Registrasi Berhasil!",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (err) {
      if (err.response) {
        Swal.fire({
          icon: "error",
          title: "Error",
          scrollbarPadding: false,
          text: err.response.data.msg,
        });
      }
    }
  };
  const resetForm = () => {
    setNamaLengkap("");
    setGelarDepan("");
    setGelarBelakang("");
    setTempatLahir("");
    setTanggalLahir("");
    setNegaraAsal("");
    setProvinsiInstitusi("");
    setKotaInstitusi("");
    setNamaInstitusi("");
    setProgramStudi("");
    setJenjangAkademik("");
    setPendidikanTerakhir("");
    setJenjangAkademik("");
    setBidangKeahlian1("");
    setBidangKeahlian2("");
    setBidangKeahlian3("");
    setNidn("");
    setKtp("");
    setNomorHandphone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setRole("");
  };

  return (
    <div
      style={{
        backgroundColor: "#f0ebeb",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="lg">
        <Card
          variant="outlined"
          sx={{
            padding: "15px",
            margin: "10px",
            borderRadius: 5,
          }}
        >
          <Box
            sx={{
              margin: "15px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                textTransform: "uppercase",
                marginBottom: "20px",
              }}
            >
              Registrasi Anggota
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography>*Role</Typography>
                <CustomSelectField
                  value={role}
                  rule={(value) => isBlank(value, "Role")}
                  onChange={(event) => {
                    const newRole = event.target.value;
                    setRole(newRole);
                    if (newRole !== "Dosen") {
                      setNidn("");
                    }
                  }}
                  menuItems={roleMenuItems.map((role, index) => (
                    <MenuItem key={index} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*KTP</Typography>
                <CustomNumberField
                  value={ktp}
                  rule={isKtpValid}
                  onChange={(event) => setKtp(event.target.value)}
                  maxInputLength={16}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography>*Nama Lengkap</Typography>
                <CustomTextField
                  value={namaLengkap}
                  rule={(value) => isBlank(value, "Nama Lengkap")}
                  onChange={(event) => setNamaLengkap(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography> Gelar Depan</Typography>
                <CustomTextField
                  value={gelarDepan}
                  onChange={(event) => setGelarDepan(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography> Gelar Belakang</Typography>
                <CustomTextField
                  value={gelarBelakang}
                  onChange={(event) => setGelarBelakang(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*Tempat Lahir</Typography>
                <CustomTextField
                  value={tempatLahir}
                  rule={(value) => isBlank(value, "Tempat Lahir")}
                  onChange={(event) => setTempatLahir(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*Tanggal Lahir</Typography>
                <CustomDatePicker
                  value={tanggalLahir}
                  onChange={(event) => setTanggalLahir(event)}
                  format={"DD/MM/YYYY"}
                />
              </Grid>
              {/* <Grid item md={12} xs={12}>
                <Typography>*Negara Asal</Typography>
                <CustomSelectField
                  value={negaraAsal}
                  rule={(value) => isBlank(value, "Negara Asal")}
                  onChange={(event) => {
                    setNegaraAsal(event.target.value);
                    setProvinsiInstitusi("");
                    setKotaInstitusi("");
                    setNamaInstitusi("");
                  }}
                  menuItems={negaraAsalItems.map((country, index) => (
                    <MenuItem key={index} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                <Typography>*Negara Asal</Typography>
                <CustomAutocompleteField
                  value={negaraAsal}
                  rule={(value) => isBlank(value, "Negara Asal")}
                  onChange={(newValue) => {
                    setNegaraAsal(newValue);
                    setProvinsiInstitusi("");
                    setKotaInstitusi("");
                    setNamaInstitusi("");
                  }}
                  options={negaraAsalItems}
                  placeholder="Pilih Negara Asal"
                  searchBy="code"
                />
              </Grid>
              {/* <Grid item md={12} xs={12}>
                <Typography>*Provinsi Institusi</Typography>
                <CustomSelectField
                  value={provinsiInstitusi}
                  rule={(value) => isBlank(value, "Provinsi Institusi")}
                  onChange={(event) => {
                    setProvinsiInstitusi(event.target.value);
                    setKotaInstitusi("");
                    setNamaInstitusi("");
                  }}
                  disabled={negaraAsal === ""}
                  menuItems={provinsiInstitusiItems.map((province, index) => (
                    <MenuItem key={index} value={province.id}>
                      {province.name}
                    </MenuItem>
                  ))}
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                <Typography>*Provinsi Institusi</Typography>
                <CustomAutocompleteField
                  value={provinsiInstitusi}
                  rule={(value) => isBlank(value, "Provinsi Institusi")}
                  onChange={(newValue) => {
                    setProvinsiInstitusi(newValue);
                    setKotaInstitusi("");
                    setNamaInstitusi("");
                  }}
                  disabled={negaraAsal === ""}
                  options={provinsiInstitusiItems}
                  placeholder="Pilih Provinsi Institusi"
                  searchBy="id"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography>*Kota Institusi</Typography>
                <CustomAutocompleteField
                  value={kotaInstitusi}
                  rule={(value) => isBlank(value, "Kota Institusi")}
                  onChange={(newValue) => {
                    setKotaInstitusi(newValue);
                    setNamaInstitusi("");
                  }}
                  disabled={provinsiInstitusi === ""}
                  options={kotaInstitusiItems}
                  placeholder="Pilih Kota Institusi"
                  searchBy="name"
                />
              </Grid>
              {/* <Grid item md={12} xs={12}>
                <Typography>*Kota Institusi</Typography>
                <CustomSelectField
                  value={kotaInstitusi}
                  rule={(value) => isBlank(value, "Kota Institusi")}
                  onChange={(event) => {
                    setKotaInstitusi(event.target.value);
                    setNamaInstitusi("");
                  }}
                  disabled={provinsiInstitusi === ""}
                  menuItems={kotaInstitusiItems.map((city, index) => (
                    <MenuItem key={index} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                <Typography>*Nama Institusi</Typography>
                <CustomAutocompleteField
                  value={namaInstitusi}
                  rule={(value) => isBlank(value, "Nama Institusi")}
                  onChange={(newValue) => {
                    setNamaInstitusi(newValue);
                  }}
                  disabled={kotaInstitusi === ""}
                  options={namaInstitusiItems}
                  placeholder="Pilih Institusi"
                  // searchBy="name"
                />
              </Grid>
              {/* <Grid item md={12} xs={12}>
                <Typography>*Nama Institusi</Typography>
                <CustomSelectField
                  value={namaInstitusi}
                  rule={(value) => isBlank(value, "Nama Institusi")}
                  onChange={(event) => setNamaInstitusi(event.target.value)}
                  disabled={kotaInstitusi === ""}
                  menuItems={namaInstitusiItems.map((institution, index) => (
                    <MenuItem key={index} value={institution.name}>
                      {institution.name}
                    </MenuItem>
                  ))}
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                <Typography>*Program Studi</Typography>
                <CustomTextField
                  value={programStudi}
                  rule={(value) => isBlank(value, "Program Studi")}
                  onChange={(event) => setProgramStudi(event.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Jenjang Mengajar</Typography>
                <CustomSelectField
                  value={jenjangMengajar}
                  onChange={(event) => setJenjangMengajar(event.target.value)}
                  menuItems={jenjangMengajarItems.map((level, index) => (
                    <MenuItem key={index} value={level.name}>
                      {level.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Pendidikan Terakhir</Typography>
                <CustomSelectField
                  value={pendidikanTerakhir}
                  onChange={(event) =>
                    setPendidikanTerakhir(event.target.value)
                  }
                  menuItems={pendidikanTerakhirItems.map(
                    (lastEducation, index) => (
                      <MenuItem key={index} value={lastEducation.name}>
                        {lastEducation.name}
                      </MenuItem>
                    )
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Jenjang Akademik</Typography>
                <CustomSelectField
                  value={jenjangAkademik}
                  onChange={(event) => setJenjangAkademik(event.target.value)}
                  menuItems={jenjangAkademikItems.map(
                    (academicLevel, index) => (
                      <MenuItem key={index} value={academicLevel.name}>
                        {academicLevel.name}
                      </MenuItem>
                    )
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Bidang Keahlian 1</Typography>
                <CustomTextField
                  value={bidangKeahlian1}
                  onChange={(event) => setBidangKeahlian1(event.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Bidang Keahlian 2</Typography>
                <CustomTextField
                  value={bidangKeahlian2}
                  onChange={(event) => setBidangKeahlian2(event.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Bidang Keahlian 3</Typography>
                <CustomTextField
                  value={bidangKeahlian3}
                  onChange={(event) => setBidangKeahlian3(event.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography>NIDN</Typography>
                <CustomNumberField
                  value={nidn}
                  onChange={(event) => setNidn(event.target.value)}
                  disabled={role !== "Dosen"}
                  rule={() => isNidnValid(role, nidn)}
                  maxInputLength={10}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography>*Nomor Handphone</Typography>
                <CustomNumberField
                  value={nomorHandphone}
                  rule={(value) => isBlank(value, "Nomor Handphone")}
                  onChange={(event) => setNomorHandphone(event.target.value)}
                  maxInputLength={16}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography>*Alamat Email</Typography>
                <CustomTextField
                  type="email"
                  value={email}
                  rule={isEmailValid}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*Password</Typography>
                <CustomTextField
                  type="password"
                  value={password}
                  rule={isPasswordValid}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography>*Confirm Password</Typography>
                <CustomTextField
                  type="password"
                  value={confirmPassword}
                  rule={(value) => doesPasswordsMatch(password, value)}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </Grid>
              {/* <Grid item md={4} xs={12}>
                <Typography>*Upload Foto</Typography>
                <CustomImageUpload
                  onChange={handleImageUpload}
                  id="image"
                  label="Upload Profile Picture"
                />
              </Grid>
              <Grid item md={8} xs={12}></Grid> */}
              <Grid item md={6} xs={12}>
                <CustomButton
                  text="Confirm"
                  baseColor="#003158"
                  hoverColor="#00213A"
                  onClick={registerUser}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomButton
                  text="Reset"
                  baseColor="#A92027"
                  hoverColor="#8C1016"
                  onClick={resetForm}
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                marginTop: "10px",
              }}
            >
              <Link to="/" style={linkStyle}>
                kembali ke halaman login
              </Link>
            </Grid>
          </Box>
        </Card>
      </Container>
      <Grid item md={12} xs={12} fullwidth="true">
        <CustomFooter text="IKDKI © 2024 by Ikatan Dosen Katholik Indonesia" />
      </Grid>
    </div>
  );
};

export default Register;
