import React, { useState, useEffect } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import debounce from "lodash/debounce";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomTextField = ({
  value: propValue,
  onChange: propOnChange,
  onBlur,
  maxInputLength,
  rule,
  id,
  disabled,
  backgroundColor,
  icon,
  visibilityColor,
  width,
  placeholder,
  type,
  onSubmit,
  isFullWidth = true,
  ...props
}) => {
  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const [value, setValue] = useState(propValue);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleValidation = debounce((value) => {
    if (rule) {
      const { isValid, errorMessage } = rule(value);
      setError(!isValid);
      setHelperText(errorMessage);
    }
  }, 300);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && onSubmit) {
      onSubmit();
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    propOnChange({ target: { value } });
    handleValidation(value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth={isFullWidth} sx={{ width: { width } }}>
      <TextField
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error}
        onKeyDown={handleKeyDown}
        helperText={helperText}
        disabled={disabled}
        placeholder={placeholder}
        variant={disabled ? "filled" : "outlined"}
        type={showPassword ? "text" : type}
        sx={{
          ...(disabled && {
            "& .MuiFilledInput-root:before": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-root:after": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-root:hover:before": {
              borderBottom: "none !important",
            },
            "& .MuiFilledInput-input": {
              transform: "translate(5px, -7px)",
            },
          }),
        }}
        InputProps={{
          style: {
            borderRadius: 20,
            fontSize: "15px",
            backgroundColor: disabled ? backgroundColor : "white",
          },
          inputProps: {
            maxLength: maxInputLength,
          },
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : null,
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  sx={{ color: { visibilityColor } }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        fullWidth
        {...props}
      />
    </FormControl>
  );
};

export default CustomTextField;
