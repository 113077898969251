import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapModule from "highcharts/modules/map";
import axios from "axios";

mapModule(Highcharts);

const LecturersByProvinceChart = () => {
  const [mapData, setMapData] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchTopology = async () => {
      try {
        const response = await fetch(
          "https://code.highcharts.com/mapdata/countries/id/id-all.topo.json"
        );
        const topology = await response.json();
        setMapData(topology);
        Highcharts.maps["id"] = topology;
      } catch (error) {
        console.error("Error fetching topology data:", error);
      }
    };

    const fetchData = async () => {
      const jwtToken = localStorage.getItem("token");

      try {
        if (jwtToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/users/role/province-count?role=Dosen`,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );
          setChartData(response.data.data);
        } else {
          console.error("JWT token is not available");
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchTopology();
    fetchData();
  }, []);

  const formattedChartData = Object.entries(chartData || {}).map(
    ([provinceId, userCount]) => [provinceId, userCount]
  );

  const mapOptions = {
    chart: {
      map: "id",
    },
    title: {
      text: null,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
    colorAxis: {
      min: 0,
      minColor: "#ff9991",
      maxColor: "#c41104",
    },
    series: [
      {
        data: formattedChartData,
        mapData: Highcharts.maps["id"],
        joinBy: "hc-key",
        name: "Jumlah Dosen",
        states: {
          hover: {
            color: "#BADA55",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    ],
  };

  return (
    <div>
      {mapData && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"mapChart"}
          options={mapOptions}
        />
      )}
    </div>
  );
};

export default LecturersByProvinceChart;