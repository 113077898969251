import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const LecturerByAcademicLevelChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    try {
      if (jwtToken) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_HOST}/users/role/academic-level-count?role=Dosen`,
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                },
              }
            );
            setChartData(response.data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }, []);

  useEffect(() => {
    if (chartData) {
      chartInstance.current = new Chart(chartRef.current, {
        type: "radar",
        data: {
          labels: Object.keys(chartData),
          datasets: [
            {
              label: "# jumlah dosen",
              data: Object.values(chartData),
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            r: {
              suggestedMin: 0,
              suggestedMax: 10, // Adjust max scale value as needed
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef} />;
};

export default LecturerByAcademicLevelChart;