import React from "react";
import LayoutNew from "./Layout";
import ReportingMenu from "../components/menu/admin/ReportingMenu";

const Reporting = () => {
  return (
    <LayoutNew>
      <ReportingMenu />
    </LayoutNew>
  );
};
export default Reporting;
