import React from "react";
import LayoutNew from "./Layout";
import EditPengabdianMenu from "../components/menu/user/EditPengabdianMenu";

const EditPengabdian = () => {
  return (
    <LayoutNew>
      <EditPengabdianMenu />
    </LayoutNew>
  );
};
export default EditPengabdian;
