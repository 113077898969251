import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import UserUpdateData from "../../data/UserUpdateData";

const UpdateUserMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Update User Profile"} />
      <UserUpdateData />
    </div>
  );
};

export default UpdateUserMenu;
