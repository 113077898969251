import React from "react";
import LayoutNew from "./Layout";
import EditUserRequestMenu from "../components/menu/admin/EditUserRequestMenu";

const RequestEditUser = () => {
  return (
    <LayoutNew>
      <EditUserRequestMenu />
    </LayoutNew>
  );
};
export default RequestEditUser;
