import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import ConfirmationUserRequestData from "../../data/ConfirmationUserRequestData";

const LogUserMenu = () => {
  return (
    <div>
      <DashboardTitleComponent text={"Log User"} />
    </div>
  );
};

export default LogUserMenu;
