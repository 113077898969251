import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import axios from "axios";

const BarChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    try {
      if (jwtToken) {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_HOST}/users/role/institution-count?role=Dosen`,
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                },
              }
            );
            setChartData(response.data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }, []);

  useEffect(() => {
    if (chartData) {
      const institutions = Object.keys(chartData);
      const counts = Object.values(chartData);

      const data = {
        labels: institutions,
        datasets: [
          {
            label: "Count",
            data: counts,
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      };

      chartInstance.current = new Chart(chartRef.current, {
        type: "bar",
        data: data,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
            x: {
              ticks: {
                maxRotation: 90,
                minRotation: 90,
              },
            },
          },
        },
      });
    }

    // Ensure the chart instance is destroyed when component is unmounted
    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartData]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
