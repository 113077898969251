import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../custom/CustomTextField";
import CustomButton from "../../custom/CustomButton";
import DashboardTitleComponent from "../../custom/DashboardTitleComponent";
import axios from "axios";
import { Switch } from "@mui/material";
import Swal from "sweetalert2";
import {
  isBlank,
  isPasswordValid,
  doesPasswordsMatch,
} from "../../../utils/ValidationRules";
import { useUser } from "../../../pages/UserContext";

const AdminProfileMenu = () => {
  const userDetails = useUser();
  const name = userDetails?.nama_lengkap;
  const [token, setToken] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    setToken(jwtToken);
  }, []);

  const handleSwitchChange = (event) => {
    setShowPasswordFields(event.target.checked);
    if (!event.target.checked) {
      setPassword("");
      setConfirmPassword("");
    }
  };

  const updateUserProfile = (event) => {
    event.preventDefault();
    Swal.fire({
      text: "Lakukan perubahan data ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, perbaharukan",
      cancelButtonText: "Tidak, jangan lakukan perubahan",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `${process.env.REACT_APP_BACKEND_HOST}/users/profile`,
            {
              namaLengkap: namaLengkap,
              password: password,
              confirmPassword: confirmPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: err.response.data.msg,
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <div>
      <DashboardTitleComponent text={"Profile Admin"} />
      <Grid item md={12} xs={12}>
        <Typography>*Nama</Typography>
        <CustomTextField
          value={name}
          rule={(value) => isBlank(value, "Nama Lengkap")}
          onChange={(event) => setNamaLengkap(event.target.value)}
        />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
        sx={{
          marginTop: "0.5em",
        }}
      >
        <Typography>Ubah Kata Sandi</Typography>
        <Switch checked={showPasswordFields} onChange={handleSwitchChange} />
      </Grid>

      {showPasswordFields && (
        <>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              marginTop: "0.25em",
            }}
          >
            <Typography>*Password</Typography>
            <CustomTextField
              type="password"
              value={password}
              rule={isPasswordValid}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography>*Confirm Password</Typography>
            <CustomTextField
              type="password"
              value={confirmPassword}
              rule={(value) => doesPasswordsMatch(password, value)}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </Grid>
        </>
      )}
      <Grid
        item
        md={4}
        xs={6}
        sx={{
          marginTop: "1em",
        }}
      >
        <CustomButton
          text="Simpan"
          baseColor="#003158"
          hoverColor="#00213A"
          onClick={updateUserProfile}
        />
      </Grid>
    </div>
  );
};

export default AdminProfileMenu;
