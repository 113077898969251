import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import "bulma/css/bulma.css";
import axios from "axios";
import { AuthProvider } from './components/AuthProvider';
import { UserProvider } from './pages/UserContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

axios.defaults.withCredentials = true;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <AuthProvider excludedRoutes={
          [
            '/',
            '/test',
            '/register',
            '/reset-password',
            '/change-user-password/:token',
            '/pendaftaran-rakernas'
          ]}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);