import { Grid, MenuItem, Switch, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import CustomNumberField from "../custom/CustomNumberField";
import {
  isBlank,
  isEmailValid,
  isKtpValid,
  isNidnValid,
} from "../../utils/ValidationRules";
import CustomTextField from "../custom/CustomTextField";
import CustomDatePicker from "../custom/CustomDatePicker";
import CustomSelectField from "../custom/CustomSelectField";
import CustomButton from "../custom/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

export default function AdminEditMemberData() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [ktp, setKtp] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [gelarDepan, setGelarDepan] = useState("");
  const [gelarBelakang, setGelarBelakang] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [nidn, setNidn] = useState("");
  const [email, setEmail] = useState("");
  const [negaraAsal, setNegaraAsal] = useState("");
  const [provinsiInstitusi, setProvinsiInstitusi] = useState("");
  const [kotaInstitusi, setKotaInstitusi] = useState("");
  const [namaInstitusi, setNamaInstitusi] = useState("");
  const [jenjangMengajar, setJenjangMengajar] = useState("");
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState("");
  const [jenjangAkademik, setJenjangAkademik] = useState("");
  const [bidangKeahlian1, setBidangKeahlian1] = useState("");
  const [bidangKeahlian2, setBidangKeahlian2] = useState("");
  const [bidangKeahlian3, setBidangKeahlian3] = useState("");
  const [nomorHandphone, setNomorHandphone] = useState("");
  const [programStudi, setProgramStudi] = useState("");
  const [status, setStatus] = useState("");
  const [tanggalMeninggal, setTanggalMeninggal] = useState(null);

  // Store Roles
  const [negaraAsalItems, setNegaraAsalItems] = useState([]);
  const [provinsiInstitusiItems, setProvinsiInstitusiItems] = useState([]);
  const [kotaInstitusiItems, setKotaInstitusiItems] = useState([]);
  const [namaInstitusiItems, setNamaInstitusiItems] = useState([]);
  const [jenjangMengajarItems, setJenjangMengajarItems] = useState([]);
  const [pendidikanTerakhirItems, setPendidikanTerakhirItems] = useState([]);
  const [jenjangAkademikItems, setJenjangAkademikItems] = useState([]);
  const [statusItems, setStatusItems] = useState([]);
  const [showDateDeceased, setShowDateDeceased] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const jwtToken = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_HOST}/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
          params: {
            uuid: userId,
          },
        }
      );
      if (response.status === 200) {
        const userData = response.data.data[0];
        setKtp(userData.ktp);
        setNamaLengkap(userData.nama_lengkap);
        setGelarDepan(userData.gelar_depan);
        setGelarBelakang(userData.gelar_belakang);
        setTempatLahir(userData.tempat_lahir);
        setTanggalLahir(dayjs(userData.tanggal_lahir));
        setNegaraAsal(userData.negara_asal);
        setProvinsiInstitusi(userData.provinsi_institusi);
        setKotaInstitusi(userData.kota_institusi);
        setNamaInstitusi(userData.institusi_asal);
        setProgramStudi(userData.prodi);
        setJenjangMengajar(userData.jenjang);
        setPendidikanTerakhir(userData.pendidikan);
        setJenjangAkademik(userData.jenjang_akademik);
        setBidangKeahlian1(userData.keahlian_1);
        setBidangKeahlian2(userData.keahlian_2);
        setBidangKeahlian3(userData.keahlian_3);
        setNidn(userData.nidn);
        setNomorHandphone(userData.no_hp);
        setEmail(userData.email);
        setTanggalLahir(userData.tanggal_lahir);
        if (userData.tanggal_meninggal) {
          setShowDateDeceased(true);
          setTanggalMeninggal(dayjs(userData.tanggal_meninggal));
        }
        if (userData.is_member) {
          setStatus("1");
        } else {
          setStatus("0");
        }
      }
      try {
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchUserData();
  }, [userId]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          countries,
          jenjangMengajar,
          pendidikanTerakhir,
          jenjangAkademik,
          status,
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/countries`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/teaching_levels`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/educations`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/academic_levels`),
          axios.get(`${process.env.REACT_APP_BACKEND_HOST}/status`),
        ]);

        setNegaraAsalItems(countries.data.data);
        setJenjangMengajarItems(jenjangMengajar.data.data);
        setPendidikanTerakhirItems(pendidikanTerakhir.data.data);
        setJenjangAkademikItems(jenjangAkademik.data.data);
        setStatusItems(status.data.data);

        if (negaraAsal) {
          const provinces = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/provinces?country_code=${negaraAsal}`
          );
          setProvinsiInstitusiItems(provinces.data.data);
        }

        if (provinsiInstitusi) {
          const regencies = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/regencies?province_id=${provinsiInstitusi}`
          );
          setKotaInstitusiItems(regencies.data.data);
        }

        if (kotaInstitusi) {
          const institutions = await axios.get(
            `${process.env.REACT_APP_BACKEND_HOST}/institutions?regency_name=${kotaInstitusi}`
          );
          setNamaInstitusiItems(institutions.data.data);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [negaraAsal, provinsiInstitusi, kotaInstitusi]);

  const handleSwitchChange = (event) => {
    setShowDateDeceased(event.target.checked);
    if (!event.target.checked) {
      setTanggalMeninggal(null);
    }
  };

  const userUpdateByAdmin = async (e) => {
    const jwtToken = localStorage.getItem("token");
    e.preventDefault();
    Swal.fire({
      text: "Lakukan pembaharuan data ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, lakukan",
      cancelButtonText: "Tidak",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .put(
            `${process.env.REACT_APP_BACKEND_HOST}/users/admin`,
            {
              namaLengkap: namaLengkap,
              gelarDepan: gelarDepan,
              gelarBelakang: gelarBelakang,
              programStudi: programStudi,
              jenjangMengajar: jenjangMengajar,
              pendidikanTerakhir: pendidikanTerakhir,
              jenjangAkademik: jenjangAkademik,
              bidangKeahlian1: bidangKeahlian1,
              bidangKeahlian2: bidangKeahlian2,
              bidangKeahlian3: bidangKeahlian3,
              nomorHandphone: nomorHandphone,
              email: email,
              status: status,
              tanggalMeninggal: tanggalMeninggal,
              userId: userId,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              icon: "success",
              text: "Berhasil Update",
              timer: 1500,
              showConfirmButton: false,
            });
            navigate("/anggota");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              text: "Gagal Update",
              timer: 1500,
              showConfirmButton: false,
            });
          });
      }
    });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography>KTP</Typography>
          <CustomNumberField
            value={ktp}
            rule={isKtpValid}
            onChange={(event) => setKtp(event.target.value)}
            maxInputLength={16}
            disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Nama Lengkap</Typography>
          <CustomTextField
            value={namaLengkap}
            rule={(value) => isBlank(value, "Nama Lengkap")}
            onChange={(event) => setNamaLengkap(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Gelar Depan</Typography>
          <CustomTextField
            value={gelarDepan}
            onChange={(event) => setGelarDepan(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Gelar Belakang</Typography>
          <CustomTextField
            value={gelarBelakang}
            onChange={(event) => setGelarBelakang(event.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Tempat Lahir</Typography>
          <CustomTextField
            value={tempatLahir}
            rule={(value) => isBlank(value, "Tempat Lahir")}
            onChange={(event) => setTempatLahir(event.target.value)}
            disabled={true}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Tanggal Lahir</Typography>
          <CustomDatePicker
            value={tanggalLahir}
            onChange={(event) => setTanggalLahir(event)}
            format={"DD/MM/YYYY"}
            disabled={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>Negara Asal</Typography>
          <CustomSelectField
            value={negaraAsal}
            placeholder={negaraAsal}
            rule={(value) => isBlank(value, "Negara Asal")}
            onChange={(event) => {
              setNegaraAsal(event.target.value);
            }}
            menuItems={negaraAsalItems.map((country, index) => (
              <MenuItem key={index} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
            disabled={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Provinsi Institusi</Typography>
          <CustomSelectField
            value={provinsiInstitusi}
            placeholder={provinsiInstitusi}
            rule={(value) => isBlank(value, "Provinsi Institusi")}
            disabled={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Kota Institusi</Typography>
          <CustomSelectField
            value={kotaInstitusi}
            placeholder={kotaInstitusi}
            rule={(value) => isBlank(value, "Kota Institusi")}
            disabled={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Nama Institusi</Typography>
          <CustomSelectField
            value={namaInstitusi}
            placeholder={namaInstitusi}
            rule={(value) => isBlank(value, "Nama Institusi")}
            onChange={(event) => setNamaInstitusi(event.target.value)}
            disabled={true}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Program Studi</Typography>
          <CustomTextField
            value={programStudi}
            rule={(value) => isBlank(value, "Program Studi")}
            onChange={(event) => setProgramStudi(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Jenjang Mengajar</Typography>
          <CustomSelectField
            value={jenjangMengajar}
            onChange={(event) => setJenjangMengajar(event.target.value)}
            menuItems={jenjangMengajarItems.map((level, index) => (
              <MenuItem key={index} value={level.name}>
                {level.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Pendidikan Terakhir</Typography>
          <CustomSelectField
            value={pendidikanTerakhir}
            onChange={(event) => setPendidikanTerakhir(event.target.value)}
            menuItems={pendidikanTerakhirItems.map((lastEducation, index) => (
              <MenuItem key={index} value={lastEducation.name}>
                {lastEducation.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Jenjang Akademik</Typography>
          <CustomSelectField
            value={jenjangAkademik}
            onChange={(event) => setJenjangAkademik(event.target.value)}
            menuItems={jenjangAkademikItems.map((academicLevel, index) => (
              <MenuItem key={index} value={academicLevel.name}>
                {academicLevel.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 1</Typography>
          <CustomTextField
            value={bidangKeahlian1}
            onChange={(event) => setBidangKeahlian1(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 2</Typography>
          <CustomTextField
            value={bidangKeahlian2}
            onChange={(event) => setBidangKeahlian2(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Bidang Keahlian 3</Typography>
          <CustomTextField
            value={bidangKeahlian3}
            onChange={(event) => setBidangKeahlian3(event.target.value)}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>NIDN</Typography>
          <CustomNumberField
            value={nidn}
            onChange={(event) => setNidn(event.target.value)}
            disabled={true}
            maxInputLength={10}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Nomor Handphone</Typography>
          <CustomNumberField
            value={nomorHandphone}
            rule={(value) => isBlank(value, "Nomor Handphone")}
            onChange={(event) => setNomorHandphone(event.target.value)}
            maxInputLength={16}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography>*Alamat Email</Typography>
          <CustomTextField
            type="email"
            value={email}
            rule={isEmailValid}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <Typography>Status</Typography>
          <CustomSelectField
            value={status}
            onChange={(event) => setStatus(event.target.value)}
            menuItems={statusItems.map((status, index) => (
              <MenuItem key={index} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <Typography>Anggota Meninggal ?</Typography>
          <Switch checked={showDateDeceased} onChange={handleSwitchChange} />
        </Grid>
        {showDateDeceased && (
          <>
            <Grid item md={5} xs={6}>
              <Typography>Tanggal Meninggal</Typography>
              <CustomDatePicker
                value={tanggalMeninggal}
                // minDate={tanggalLahir}
                onChange={(event) => setTanggalMeninggal(event)}
                format={"DD/MM/YYYY"}
              />
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12}>
          <CustomButton
            text="Confirm"
            baseColor="#003158"
            hoverColor="#00213A"
            onClick={userUpdateByAdmin}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CustomButton
            text="Reset"
            baseColor="#A92027"
            hoverColor="#8C1016"
            // onClick={resetForm}
          />
        </Grid>
      </Grid>
    </div>
  );
}
