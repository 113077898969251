import React from "react";
import LayoutNew from "./Layout";
import LogUserMenu from "../components/menu/admin/LogUserMenu";

const LogUser = () => {
  return (
    <LayoutNew>
      <LogUserMenu />
    </LayoutNew>
  );
};
export default LogUser;
