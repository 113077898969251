import React from "react";
import LayoutNew from "./Layout";
import InsertPenelitianMenu from "../components/menu/user/InsertPenelitianMenu";

const IsiPenelitian = () => {
  return (
    <LayoutNew>
      <InsertPenelitianMenu />
    </LayoutNew>
  );
};
export default IsiPenelitian;
