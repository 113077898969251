import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import debounce from "lodash/debounce";

const CustomDatePicker = ({
  value: propValue,
  onChange: propOnChange,
  onBlur,
  rule,
  minDate,
  format = "MM/DD/YYYY",
  ...props
}) => {
  const defaultDate = dayjs("2000-01-01");

  // useEffect(() => {
  //   setValue(propValue);
  // }, [propValue]);

  useEffect(() => {
    setValue(propValue ? dayjs(propValue) : defaultDate);
  }, [propValue]);

  const [value, setValue] = useState(propValue || defaultDate); // Use propValue if available, otherwise use defaultDate
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const today = dayjs();

  const handleValidation = debounce((value) => {
    if (rule) {
      const { isValid, errorMessage } = rule(value);
      setError(!isValid);
      setHelperText(errorMessage);
    }
  }, 300);

  const handleChange = (date) => {
    setValue(date);
    propOnChange(date);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
    propOnChange({ target: { value } });
    handleValidation(value);
  };

  return (
    <FormControl fullWidth error={error}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          format={format}
          maxDate={today}
          slotProps={{ textField: { fullWidth: true, helperText: helperText } }}
          sx={{
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderRadius: 5,
            },
          }}
          {...props}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default CustomDatePicker;
