import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../pages/UserContext";

const AuthContext = createContext();

export const AuthProvider = ({ children, excludedRoutes }) => {
  const userDetails = useUser();
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [role, setRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    setToken(jwtToken);
  }, []);

  useEffect(() => {
    const isExcludedRoute = excludedRoutes.some((route) => {
      const pattern = new RegExp(`^${route.replace(/:[^/]+/g, "[^/]+")}$`);
      return pattern.test(location.pathname);
    });

    // KHUSUS Untuk Page Pendaftaran Rakernas
    if (location.pathname === "/pendaftaran-rakernas") {
      navigate("/pendaftaran-rakernas");
      return;
    }

    if (token === null) {
      setIsAuthenticated(false);
      setIsMember(false);
      setRole(null);
      if (!isExcludedRoute) {
        navigate("/");
      }
      return;
    }
    if (token && userDetails) {
      setIsMember(userDetails.is_member);
      setRole(userDetails.role);
      try {
        if (userDetails.is_member && location.pathname === "/waiting") {
          if (userDetails.role !== "admin") {
            navigate("/update");
          } else {
            navigate("/dashboard");
          }
        } else if (userDetails.is_member) {
          setIsAuthenticated(true);
          if (!isExcludedRoute) {
            navigate(location.pathname);
          } else {
            if (userDetails.role !== "admin") {
              navigate("/update");
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          setIsMember(false);
          navigate("/waiting");
        }
      } catch (err) {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setIsMember(false);
        navigate("/");
      }
    }
  }, [userDetails, token, excludedRoutes]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isMember,
        setIsMember,
        role,
        setRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
