import React from "react";
import LayoutNew from "./Layout";
import Institutions from "../components/menu/general/InstitutionsMenu";

const Institusi = () => {
  return (
    <LayoutNew>
      <Institutions />
    </LayoutNew>
  );
};
export default Institusi;
