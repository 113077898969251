import React from "react";
import LayoutNew from "./Layout";
import InsertPengajaranMenu from "../components/menu/user/InsertPengajaranMenu";

const IsiPengajaran = () => {
  return (
    <LayoutNew>
      <InsertPengajaranMenu />
    </LayoutNew>
  );
};
export default IsiPengajaran;
